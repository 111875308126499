import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=c8bb6ea2&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.stad-antwerpen/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTable} from 'bootstrap-vue'
    installComponents(component, {BTable})
    

export default component.exports